/**
 * @file Date helper constants and functions.
 */

import dateFormat from "dateformat";

/**
 * @constant
 * @type {string}
 * @default "dd mmm yyyy"
 * @description Returns a date format string to use to return a string in the format `"08 Jun 2020"`
 * Compatible with `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * Do _not_ use with `date-fns` {@link https://date-fns.org/} unless you want the output to be `"08 000 2020"`
 * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
 * @see https://github.com/felixge/node-dateformat
 */
export const DATE_FORMAT_SHORT_DAY_MONTH_YEAR = "dd mmm yyyy";

/**
 * @const
 * @type {string}
 * @default "dd/MM/yyyy"
 * @description Returns a date _format_ string to use to return a string in the format `"08/06/2020"`
 * Compatible with `date-fns` {@link https://date-fns.org/}
 * Do _not_ use with `dateFormat` {@link https://github.com/felixge/node-dateformat} as the `MM` translates to minutes
 * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
 * @see https://github.com/felixge/node-dateformat
 */
export const DATE_FNS_DD_MM_YYYY = "dd/MM/yyyy";

/**
 * @const
 * @type {string}
 * @default "dd/mm/yyyy"
 * @description Returns a date _format_ string to use to return a string in the format `"08/06/2020"`
 * Compatible with `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * Do _not_ use with `date-fns` {@link https://date-fns.org/} unless you want the output to be
 * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
 * @see https://github.com/felixge/node-dateformat
 */
export const DATE_FORMAT_DD_MM_YYYY = "dd/mm/yyyy"; // This is date-fns under the hood.

/**
 * @const
 * @type {string}
 * @default "dd mmmm"
 * @description A date format string to use to return a string in the format `"08 June"`.
 * @description Compatible with `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * @description and `date-fns` {@link https://date-fns.org/v2.28.0/docs/format}
 * @description Import like this:
 * @example <>Using named import</>
 * import { DATE_FORMAT_INFO_DD_MMMM } from 'path/to/dateHelpers.js
 * @description Use like this:
 * @example <>Using the `dateFormat` package - Returns "08 June"</>
 * const formattedDate = dateFormat(new Date("2020-06-08"), DATE_FORMAT_INFO_DD_MMMM);
 */
export const DATE_FORMAT_INFO_DD_MMMM = "dd mmmm";

/**
 * @const
 * @type {string}
 * @default
 * @description A date format string to use to return a string in the format `"08 June"`.
 * @description Compatible with `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * @description and `date-fns` {@link https://date-fns.org/v2.28.0/docs/format}
 * @description Import like this:
 * @example <>Using named import</>
 * import { DATE_FORMAT_INFO_DD_MMMM } from 'path/to/dateHelpers.js
 * @description Use like this:
 * @example <>Using the `dateFormat` package - Returns "08 June"</>
 * const formattedDate = dateFormat(new Date("2020-06-08"), DATE_FORMAT_INFO_DD_MMMM);
 */
export const DATE_FNS_INFO_DD_MMMM = "dd MMMM";

/**
 * @const
 * @default
 * @description Returns an array of all known date formats.
 * Each item is compatible with `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * or `date-fns` {@link https://date-fns.org/}
 * Essentially just for testing.
 * @example Use the array for checking existence of a type before handling.
 * import { dateFormats } from 'path/to/dateHelpers.js'
 * const trueOrFalse = dateFormats.includes("dd mm");
 *
 */
const dateFormats = [
  DATE_FNS_DD_MM_YYYY,
  DATE_FORMAT_DD_MM_YYYY,
  DATE_FORMAT_INFO_DD_MMMM,
  DATE_FORMAT_SHORT_DAY_MONTH_YEAR,
];

/**
 * @const
 * @default
 * @description Returns an array of all known date formats.
 * Each item is compatible with `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * or `date-fns` {@link https://date-fns.org/}
 * Essentially just for testing.
 * @example Use the array for checking existence of a type before handling.
 * import { dateFnsFormats } from 'path/to/dateHelpers.js'
 * const trueOrFalse = dateFnsFormats.includes("dd mm");
 */
const dateFnsFormats = [DATE_FNS_DD_MM_YYYY];

/**
 * @const
 * @default
 * @description Returns an object of all known date formats within the App.
 *
 * Named by usage to simplify when looking for the correct format.
 *
 * @example <>Use to simplify the formats for duplicated functions.</>
 * import { dateFormatsByUse } from 'path/to/dateHelpers.js'
 *
 * return (
 *  <DatePicker
 *  format={dateFormatsByUse.DATE_PICKER_FUNCTION}
 *  placeholderText={dateFormatsByUse.DATE_PICKER_DISPLAY}
 *  {...rest}
 *  />)
 *
 */
export const dateFormatsByUse = {
  DATE_FORMAT_INFO_DD_MMMM: DATE_FORMAT_INFO_DD_MMMM,
  DATE_FORMAT_TABLE_DISPLAY: DATE_FORMAT_SHORT_DAY_MONTH_YEAR,
  DATE_PICKER_DISPLAY: DATE_FORMAT_DD_MM_YYYY,
  DATE_PICKER_FUNCTION: DATE_FNS_DD_MM_YYYY,
};

/**
 * @function
 * @type {string}
 * @default
 * @description A date formatter function used to return a string in the format `"08 June"`.
 * @description Uses `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * @description Import like this:
 * @example <>Using named import</>
 * import { standardAusDateFormat } from 'path/to/dateHelpers.js
 * @description Use like this:
 * @example <>Returns "08/06/2020"</>
 * const formattedDate = standardAusDateFormat("2020-06-08", DATE_FORMAT_DD_MM_YYYY);
 * @param {string} (dateString) Should be in the unicode format `"yyyy-MM-dd"` e.g. "2020-08-21"
 * @returns {string} A string in the format {@link DATE_FORMAT_DD_MM_YYYY}
 */
export function dateFormatAusStandard(dateString: string | number | Date) {
  const dateObj = new Date(dateString);
  return dateFormat(dateObj, DATE_FORMAT_DD_MM_YYYY);
}

/**
 * @function
 * @default
 * @description A date formatter function used to return a string in the format `"08 June"`.
 * @description Uses `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * @description Import like this:
 * @param (date) {string} Should be in the unicode format `"yyyy-MM-dd"` e.g. "2020-08-21"
 * @example <>Typical Usage</>
 * import { standardAusDateFormat } from 'path/to/dateHelpers.js
 *
 * const formattedDate = standardAusDateFormat("2020-06-08", DATE_FORMAT_INFO_DD_MMMM);
 *
 * @returns {string} A string in the format {@link DATE_FORMAT_SHORT_DAY_MONTH_YEAR}
 */
export function shortAusDateFormat(date: string | number | Date | undefined) {
  return dateFormat(date, DATE_FORMAT_SHORT_DAY_MONTH_YEAR);
}

/**
 * @function
 * @default
 * @description A date formatter function used to return a string in the chosen format.
 * @description Uses `dateFormat` {@link https://github.com/felixge/node-dateformat}
 * @param date {string}
 * @param formatToReturn {string}
 * @param throwOnUnknown {boolean}
 * @example <>Typical Usage</>
 * import { standardAusDateFormat } from 'path/to/dateHelpers.js
 *
 * const formattedDate = dateFormatCustom("2020-06-08", DATE_FORMAT_INFO_DD_MMMM);
 *
 * @returns { string } A string in the format {@link DATE_FORMAT_SHORT_DAY_MONTH_YEAR}
 * @throws { error } An error object with the message `"Check the format and add to constants if this is not a mistake."`
 */
export function dateFormatCustom(
  date: string | number | Date | undefined,
  formatToReturn: string | undefined,
  throwOnUnknown: boolean
) {
  const isDev = process.env.NODE_ENV === "development";
  // @ts-expect-error
  if (dateFnsFormats.includes(formatToReturn)) {
    if ((formatToReturn = dateFormatsByUse.DATE_PICKER_FUNCTION)) {
      formatToReturn = dateFormatsByUse.DATE_PICKER_DISPLAY;
    }
  }

  // @ts-expect-error
  if (throwOnUnknown && !dateFormats.includes(formatToReturn)) {
    throw new Error(
      "Check the format and add to constants if this is not a mistake."
    );
  }
  return dateFormat(date, formatToReturn);
}

/**
 * @function
 * @default
 * @description A wrapper around `new Date()`.
 * @param { string } dateString
 * @example <>Typical Usage</>
 * import { standardJSDateFromString } from 'path/to/dateHelpers.js
 *
 * const jsDate = standardJSDateFromString("2020-06-08");
 *
 * @returns {Date} A standard JavaScript `Date` object
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
 */
export function standardJSDateFromString(dateString: string | number | Date) {
  const dateFromString = (dateString && new Date(dateString)) || new Date();
  return dateFromString;
}

export function isoDateFromString(dateString: string) {
  const parsedDate = Date.parse(dateString);
  const dateFromString = new Date(parsedDate).toISOString();
  return dateFromString;
}

/**
 * @function
 * @description Takes two plain JavaScript `Date` objects, calculates the difference in years, and returns the resulting range.
 * @param minDate {Date}
 * @param maxDate {Date}
 * @returns {number}
 */
export function getDateDifferenceInYears(
  minDate: { getUTCFullYear: () => number },
  maxDate: Date
) {
  const yearRange = maxDate.getUTCFullYear() - minDate.getUTCFullYear();
  return yearRange;
}
