import { breakpoint } from "helpers/data-helpers/breakpoint";
import { Account } from "redux-models/reduxStateTypes";
import { theme } from "theme";
import { featureFlags } from "utils/constants/feature-flags";

const loggedInNavOptions = (account: Account, isMindMatrixEnabled: boolean) => {
  const nav = [
    {
      text: "Applications",
      items: [
        // {
        //   name: "Apply Asset Finance",
        //   link: "/asset-finance",
        //   comingSoon: !isMindMatrixEnabled,
        // },
        // featureFlags.isBusinessFuelEnabled && {
        //   name: "Apply Business Loan",
        //   link: "/business-fuel",
        // },
        {
          name: "Apply",
          link: "/apply-all",
        },
        { name: "Refer Your Client", link: "/apply-and-refer" },
        { name: "Pending Applications", link: "/pending-applications" },
        { name: "View Application Status", link: "/applications" },
        { name: "View Applications by Applicant", link: "/companies" },
        { name: "View Saved Quotes", link: "/quotes" },
        // {
        //   name: "Secured Loans - Atlas",
        //   link: "https://services.scotpac.com.au/",
        //   inNewTab: true,
        // },
      ],
    },
    {
      text: "Tools",
      items: [
        { name: "Asset Finance Quote", link: "/create-quote" },
        {
          name: "Working Capital Calculator",
          link: "/working-capital-calculator-av",
        },
        // { name: "Product Selector", link: "/product-selector-av" },
        { name: "Product Selector", link: "/product-selector-tool" },
        {
          name: "Secured Loan Serviceability Calculator",
          link: process.env.REACT_APP_FILE_BL_CALC,
          inNewTab: true,
        },
        account.idTokenClaims.role === "admin" && {
          name: "Fast Doc Configuration",
          link: "/fast-doc/settings",
        },
      ],
    },
    {
      text: "Marketing",
      comingSoon: !isMindMatrixEnabled,
      items: [
        {
          name: "Newsletters",
          link: "/resources?name=Newsletters",
        },
        {
          name: "Sales Emails",
          link: "/resources?name=Sales%20Emails",
        },
        {
          name: "Campaigns",
          link: "/resources?name=Campaigns",
        },
        { name: "Social Campaigns & Blogs", link: "/social-channels" },
        { name: "Generated Leads", link: "/leads" },
        { name: "Setup Email Lists", link: "/email-lists" },
        { name: "Landing Pages", link: "/landing-pages" },
      ],
    },
    {
      text: "Resources",
      comingSoon: !isMindMatrixEnabled,
      items: [
        {
          name: "ScotPac Product Summaries",
          link: "/resources?name=ScotPac%20Product%20Summaries",
        },
        {
          name: "Other Sales Resources",
          link: "/resources?name=Other%20Sales%20Resources",
        },
      ],
    },
    {
      text: "Help",
      items: [],
      link: "https://www.scotpac.com.au/partner-portal-learning/",
      inNewTab: true,
    },
    {
      text: "Sign out",
      items: [],
      link: "/logout",
    },
    // {
    //   text: "Apply & Refer",
    //   items: getApplyAndRefer(isMindMatrixEnabled),
    // },
  ];

  if (isMindMatrixEnabled) {
    nav.splice(3, 0, {
      text: "Reporting",
      comingSoon: !isMindMatrixEnabled,
      items: [
        { name: "Newsletter & Emails Report", link: "/email-results" },
        { name: "Campaigns Report", link: "/campaign-results" },
        { name: "Social Campaigns & Blogs Report", link: "/social-results" },
        { name: "Landing Pages Report", link: "/landing-results" },
        { name: "Sales Resources Report", link: "/resources-results" },
        { name: "Contacts Report", link: "/contact-lists" },
      ],
    });
  }

  return nav;
};
const staffNavOptions = [
  {
    text: "Sign out",
    items: [],
    link: "/logout",
  },
];

export { loggedInNavOptions, staffNavOptions };

const getApplyAndRefer = (isMindMatrixEnabled: boolean) => {
  const links = [
    { name: "Fast Doc Configuration", link: "/fast-doc/settings" },
  ];

  return links;
};
