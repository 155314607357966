import { ApiDispatch } from "actions/apiDispatch";
import {
  CALL_API,
  CLEAR_PERSONALISATION_DATA,
  GET_PERSONALISATION_FAILURE,
  GET_PERSONALISATION_REQUEST,
  GET_PERSONALISATION_SUCCESS,
  SET_REMINDER_FAILURE,
  SET_REMINDER_REQUEST,
  SET_REMINDER_SUCCESS,
} from "./types";

export const clearPersonalisation = () => async (dispatch: ApiDispatch) =>
  dispatch({ type: CLEAR_PERSONALISATION_DATA });

/**
 * @route /users/{userId}/personalisation
 * @method GET
 * @desc get the information of a single user
 * @param {string} userId
 */
export const getUserPersonalisation = (userId: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/personalisation`,
      method: "GET",
      types: [
        GET_PERSONALISATION_REQUEST,
        GET_PERSONALISATION_SUCCESS,
        GET_PERSONALISATION_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/reminders/{reminderId}
 * @method POST
 * @desc sets the reminder for the user to add personalisation
 * @param {string} userId
 * @param {string} reminderId
 */
export const setReminder = (userId: string, reminderId: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/reminders/${reminderId}`,
      method: "POST",
      types: [SET_REMINDER_REQUEST, SET_REMINDER_SUCCESS, SET_REMINDER_FAILURE],
    },
  });
