export const CALL_API = "CALL_API";

/***** managers *****/
export const GET_BDMS_REQUEST = "GET_BDMS_REQUEST";
export const GET_BDMS_SUCCESS = "GET_BDMS_SUCCESS";
export const GET_BDMS_FAILURE = "GET_BDMS_FAILURE";

/***** active directory *****/

export const AAD_SET_ACCOUNT_SUCCESS = " AAD_SET_ACCOUNT_SUCCESS";
export const AAD_LOGIN_SUCCESS = "AAD_LOGIN_SUCCESS";
export const AAD_LOGIN_FAILED = "AAD_LOGIN_FAILED";
export const AAD_LOGIN_ERROR = "AAD_LOGIN_ERROR";
export const AAD_ACQUIRED_TOKEN_SUCCESS = "AAD_ACQUIRED_TOKEN_SUCCESS";
export const AAD_LOGOUT_SUCCESS = "AAD_LOGOUT_SUCCESS";
export const AAD_ACQUIRED_ACCESS_TOKEN_ERROR =
  "AAD_ACQUIRED_ACCESS_TOKEN_ERROR";
export const AAD_ID_TOKEN_HINT_EXPIRED = "AAD_ID_TOKEN_HINT_EXPIRED";
export const AAD_ID_USER_HINT_MISSING = "AAD_ID_USER_HINT_MISSING";

/***** company *****/

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const COMPANY_PREFILL_REQUEST = "COMPANY_PREFILL_REQUEST";
export const COMPANY_PREFILL_SUCCESS = "COMPANY_PREFILL_SUCCESS";
export const COMPANY_PREFILL_FAILURE = "COMPANY_PREFILL_FAILURE";

export const GET_ALL_COMPANIES_REQUEST = "GET_ALL_COMPANIES_REQUEST";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";

export const GET_ALL_BROKER_COMPANIES_REQUEST =
  "GET_ALL_BROKER_COMPANIES_REQUEST";
export const GET_ALL_BROKER_COMPANIES_SUCCESS =
  "GET_ALL_BROKER_COMPANIES_SUCCESS";
export const GET_ALL_BROKER_COMPANIES_FAILURE =
  "GET_ALL_BROKER_COMPANIES_FAILURE";

export const UPLOAD_COMPANY_IMAGE_REQUEST = "UPLOAD_COMPANY_IMAGE_REQUEST";
export const UPLOAD_COMPANY_IMAGE_SUCCESS = "UPLOAD_COMPANY_IMAGE_SUCCESS";
export const UPLOAD_COMPANY_IMAGE_FAILURE = "UPLOAD_COMPANY_IMAGE_FAILURE";

export const CLEAR_COMPANY_DATA = "CLEAR_COMPANY_DATA";

export const GET_ALL_AFCRITERIA_REQUEST = "GET_ALL_AFCRITERIA_REQUEST";
export const GET_ALL_AFCRITERIA_SUCCESS = "GET_ALL_AFCRITERIA_SUCCESS";
export const GET_ALL_AFCRITERIA_FAILURE = "GET_ALL_AFCRITERIA_FAILURE";

export const UPDATE_ALL_AFCRITERIA_REQUEST = "UPDATE_ALL_AFCRITERIA_REQUEST";
export const UPDATE_ALL_AFCRITERIA_SUCCESS = "UPDATE_ALL_AFCRITERIA_SUCCESS";
export const UPDATE_ALL_AFCRITERIA_FAILURE = "UPDATE_ALL_AFCRITERIA_FAILURE";

export const GET_SUMMARY_AFCRITERIA_REQUEST = "GET_SUMMARY_AFCRITERIA_REQUEST";
export const GET_SUMMARY_AFCRITERIA_SUCCESS = "GET_SUMMARY_AFCRITERIA_SUCCESS";
export const GET_SUMMARY_AFCRITERIA_FAILURE = "GET_SUMMARY_AFCRITERIA_FAILURE";

/****** applications ******/
export const GET_ALL_APPLICATIONS_REQUEST = "GET_ALL_APPLICATIONS_REQUEST";
export const GET_ALL_APPLICATIONS_SUCCESS = "GET_ALL_APPLICATIONS_SUCCESS";
export const GET_ALL_APPLICATIONS_FAILURE = "GET_ALL_APPLICATIONS_FAILURE";

export const GET_ALL_AFAPPLICATIONS_REQUEST = "GET_ALL_AFAPPLICATIONS_REQUEST";
export const GET_ALL_AFAPPLICATIONS_SUCCESS = "GET_ALL_AFAPPLICATIONS_SUCCESS";
export const GET_ALL_AFAPPLICATIONS_FAILURE = "GET_ALL_AFAPPLICATIONS_FAILURE";

export const UPDATE_APPLICATION_INFORMATION_REQUEST =
  "UPDATE_APPLICATION_INFORMATION_REQUEST";
export const UPDATE_APPLICATION_INFORMATION_SUCCESS =
  "UPDATE_APPLICATION_INFORMATION_SUCCESS";
export const UPDATE_APPLICATION_INFORMATION_FAILURE =
  "UPDATE_APPLICATION_INFORMATION_FAILURE";

export const CREATE_APPLICATION_REQUEST = "CREATE_APPLICATION_REQUEST";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_FAILURE = "CREATE_APPLICATION_FAILURE";

export const UPDATE_APPLICATION_REQUEST = "UPDATE_APPLICATION_REQUEST";
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS";
export const UPDATE_APPLICATION_FAILURE = "UPDATE_APPLICATION_FAILURE";

export const SUBMIT_APPLICATION_REQUEST = "SUBMIT_APPLICATION_REQUEST";
export const SUBMIT_APPLICATION_SUCCESS = "SUBMIT_APPLICATION_SUCCESS";
export const SUBMIT_APPLICATION_FAILURE = "SUBMIT_APPLICATION_FAILURE";
export const SUBMIT_APPLICATION_RECOMMENDATION =
  "SUBMIT_APPLICATION_RECOMMENDATION";

export const GET_AF_APPLICATION_REQUEST = "GET_AF_APPLICATION_REQUEST";
export const GET_AF_APPLICATION_SUCCESS = "GET_AF_APPLICATION_SUCCESS";
export const GET_AF_APPLICATION_FAILURE = "GET_AF_APPLICATION_FAILURE";

export const AF_GET_BROKER_REQUEST = "AF_GET_BROKER_REQUEST";
export const AF_GET_BROKER_SUCCESS = "AF_GET_BROKER_SUCCESS";
export const AF_GET_BROKER_FAILURE = "AF_GET_BROKER_FAILURE";

export const FINANCING_NEEDS_REQUEST = "FINANCING_NEEDS_REQUEST";
export const FINANCING_NEEDS_SUCCESS = "FINANCING_NEEDS_SUCCESS";
export const FINANCING_NEEDS_FAILURE = "FINANCING_NEEDS_FAILURE";

export const CLEAR_APPLICATION_DATA = "CLEAR_APPLICATION_DATA";
export const CLEAR_ASSET_FINANCE_ERROR = "CLEAR_ASSET_FINANCE_ERROR";
export const CLEAR_ASSET_FINANCE_RECOMMENDATION =
  "CLEAR_ASSET_FINANCE_RECOMMENDATION";

export const RETURN_APPLICATION_INITIAL_STATE =
  "RETURN_APPLICATION_INITIAL_STATE";

export const UPDATE_BROKER_NOB_REQUEST = "UPDATE_BROKER_NOB_REQUEST";
export const UPDATE_BROKER_NOB_SUCCESS = "UPDATE_BROKER_NOB_SUCCESS";
export const UPDATE_BROKER_NOB_FAILURE = "UPDATE_BROKER_NOB_FAILURE";

export const ADD_INVITE_BROKER_REQUEST = "ADD_INVITE_BROKER_REQUEST";
export const ADD_INVITE_BROKER_SUCCESS = "ADD_INVITE_BROKER_SUCCESS";
export const ADD_INVITE_BROKER_FAILURE = "ADD_INVITE_BROKER_FAILURE";
export const CLEAR_INVITED_BROKER = "CLEAR_INVITED_BROKER";
/****** bfApplications *******/

export const BF_SUBMIT_APPLICATION_REQUEST = "BF_SUBMIT_APPLICATION_REQUEST";
export const BF_SUBMIT_APPLICATION_SUCCESS = "BF_SUBMIT_APPLICATION_SUCCESS";
export const BF_SUBMIT_APPLICATION_FAILURE = "BF_SUBMIT_APPLICATION_FAILURE";

export const BF_GET_FILES_REQUEST = "BF_GET_FILES_REQUEST";
export const BF_GET_FILES_SUCCESS = "BF_GET_FILES_SUCCESS";
export const BF_GET_FILES_FAILURE = "BF_GET_FILES_FAILURE";

export const BF_UPLOAD_FILE_REQUEST = "BF_UPLOAD_FILE_REQUEST";
export const BF_UPLOAD_FILE_SUCCESS = "BF_UPLOAD_FILE_SUCCESS";
export const BF_UPLOAD_FILE_FAILURE = "BF_UPLOAD_FILE_FAILURE";

export const BF_GET_APPLICATION_REQUEST = "BF_GET_APPLICATION_REQUEST";
export const BF_GET_APPLICATION_SUCCESS = "BF_GET_APPLICATION_SUCCESS";
export const BF_GET_APPLICATION_FAILURE = "BF_GET_APPLICATION_FAILURE";

export const BF_GET_RULES_SUMMARY_REQUEST = "BF_GET_RULES_SUMMARY_REQUEST";
export const BF_GET_RULES_SUMMARY_SUCCESS = "BF_GET_RULES_SUMMARY_SUCCESS";
export const BF_GET_RULES_SUMMARY_FAILURE = "BF_GET_RULES_SUMMARY_FAILURE";

export const BF_GET_CUSTOMER_DETAILS_REQUEST =
  "BF_GET_CUSTOMER_DETAILS_REQUEST";
export const BF_GET_CUSTOMER_DETAILS_SUCCESS =
  "BF_GET_CUSTOMER_DETAILS_SUCCESS";
export const BF_GET_CUSTOMER_DETAILS_FAILURE =
  "BF_GET_CUSTOMER_DETAILS_FAILURE";

export const BF_CREATE_CUSTOMER_REQUEST = "BF_CREATE_CUSTOMER_REQUEST";
export const BF_CREATE_CUSTOMER_SUCCESS = "BF_CREATE_CUSTOMER_SUCCESS";
export const BF_CREATE_CUSTOMER_FAILURE = "BF_CREATE_CUSTOMER_FAILURE";

export const BF_UPDATE_CUSTOMER_REQUEST = "BF_UPDATE_CUSTOMER_REQUEST";
export const BF_UPDATE_CUSTOMER_SUCCESS = "BF_UPDATE_CUSTOMER_SUCCESS";
export const BF_UPDATE_CUSTOMER_FAILURE = "BF_UPDATE_CUSTOMER_FAILURE";

export const BF_CREATE_APPLICATION_REQUEST = "BF_CREATE_APPLICATION_REQUEST";
export const BF_CREATE_APPLICATION_SUCCESS = "BF_CREATE_APPLICATION_SUCCESS";
export const BF_CREATE_APPLICATION_FAILURE = "BF_CREATE_APPLICATION_FAILURE";

export const UPDATE_CUSTOMER_BF_REQUEST = "UPDATE_CUSTOMER_BF_REQUEST";
export const UPDATE_CUSTOMER_BF_SUCCESS = "UPDATE_CUSTOMER_BF_SUCCESS";
export const UPDATE_CUSTOMER_BF_FAILURE = "UPDATE_CUSTOMER_BF_FAILURE";

export const BF_GET_BROKER_REQUEST = "BF_GET_BROKER_REQUEST";
export const BF_GET_BROKER_SUCCESS = "BF_GET_BROKER_SUCCESS";
export const BF_GET_BROKER_FAILURE = "BF_GET_BROKER_FAILURE";

export const BF_UPDATE_BDM_REQUEST = "BF_UPDATE_BDM_REQUEST";
export const BF_UPDATE_BDM_SUCCESS = "BF_UPDATE_BDM_SUCCESS";
export const BF_UPDATE_BDM_FAILURE = "BF_UPDATE_BDM_FAILURE";

export const BF_UPDATE_OFFICE_HOLDERS_REQUEST =
  "BF_UPDATE_OFFICE_HOLDERS_REQUEST";
export const BF_UPDATE_OFFICE_HOLDERS_SUCCESS =
  "BF_UPDATE_OFFICE_HOLDERS_SUCCESS";
export const BF_UPDATE_OFFICE_HOLDERS_FAILURE =
  "BF_UPDATE_OFFICE_HOLDERS_FAILURE";

export const SET_ITEM_AT_PATH_BF = "SET_ITEM_AT_PATH_BF";
export const REMOVE_ITEM_AT_PATH_BF = "REMOVE_ITEM_AT_PATH_BF";
export const RESET_BUSINESS_FUEL_STATE = "RESET_BUSINESS_FUEL_STATE";

export const BF_SET_ITEM_AT_PATH = "BF_SET_ITEM_AT_PATH";
export const BF_REMOVE_ITEM_AT_PATH = "BF_REMOVE_ITEM_AT_PATH";
export const BF_RESET_STATE = "BF_RESET_STATE";
export const BF_SELECT_BROKER = "BF_SELECT_BROKER";
export const BF_SELECT_MANAGER = "BF_SELECT_MANAGER";

export const BF_UPDATE_CUSTOMER_ADDRESS_DETAILS =
  "BF_UPDATE_CUSTOMER_ADDRESS_DETAILS";

export const BF_FINANCING_NEEDS_REQUEST = "BF_FINANCING_NEEDS_REQUEST";
export const BF_FINANCING_NEEDS_SUCCESS = "BF_FINANCING_NEEDS_SUCCESS";
export const BF_FINANCING_NEEDS_FAILURE = "BF_FINANCING_NEEDS_FAILURE";

/****** quotes ******/

export const GET_QUOTES_REQUEST = "GET_QUOTES_REQUEST";
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";
export const GET_QUOTES_FAILURE = "GET_QUOTES_FAILURE";

export const SAVE_QUOTE_REQUEST = "SAVE_QUOTE_REQUEST";
export const SAVE_QUOTE_SUCCESS = "SAVE_QUOTE_SUCCESS";
export const SAVE_QUOTE_FAILURE = "SAVE_QUOTE_FAILURE";

export const GET_QUOTE_REQUEST = "GET_QUOTE_REQUEST";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";
export const GET_QUOTE_FAILURE = "GET_QUOTE_FAILURE";

export const UPDATE_QUOTE_REQUEST = "UPDATE_QUOTE_REQUEST";
export const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";
export const UPDATE_QUOTE_FAILURE = "UPDATE_QUOTE_FAILURE";

export const EMAIL_QUOTE_REQUEST = "EMAIL_QUOTE_REQUEST";
export const EMAIL_QUOTE_SUCCESS = "EMAIL_QUOTE_SUCCESS";
export const EMAIL_QUOTE_FAILURE = "EMAIL_QUOTE_FAILURE";

export const CLEAR_QUOTE_DATA = "CLEAR_QUOTE_DATA";

/****** user ********/
export const ACCEPT_TERMS_REQUEST = "ACCEPT_TERMS_REQUEST";
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS";
export const ACCEPT_TERMS_FAILURE = "ACCEPT_TERMS_FAILURE";

export const REFER_CLIENT_REQUEST = "REFER_CLIENT_REQUEST";
export const REFER_CLIENT_SUCCESS = "REFER_CLIENT_SUCCESS";
export const REFER_CLIENT_FAILURE = "REFER_CLIENT_FAILURE";

export const INVITE_BROKER_REQUEST = "INVITE_BROKER_REQUEST";
export const INVITE_BROKER_SUCCESS = "INVITE_BROKER_SUCCESS";
export const INVITE_BROKER_FAILURE = "INVITE_BROKER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

export const FAIL_USER_REQUEST = "FAIL_USER_REQUEST";
export const FAIL_USER_SUCCESS = "FAIL_USER_SUCCESS";
export const FAIL_USER_FAILURE = "FAIL_USER_FAILURE";

export const SUSPEND_USER_REQUEST = "SUSPEND_USER_REQUEST";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILURE = "SUSPEND_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const BULK_INVITE_REQUEST = "BULK_INVITE_REQUEST";
export const BULK_INVITE_SUCCESS = "BULK_INVITE_SUCCESS";
export const BULK_INVITE_FAILURE = "BULK_INVITE_FAILURE";

export const CLEAR_USER_SUCCESS = "CLEAR_USER_SUCCESS";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const SET_USER_ONBOARDING = "SET_USER_ONBOARDING";

/****** personalisation ********/
export const GET_PERSONALISATION_REQUEST = "GET_PERSONALISATION_REQUEST";
export const GET_PERSONALISATION_SUCCESS = "GET_PERSONALISATION_SUCCESS";
export const GET_PERSONALISATION_FAILURE = "GET_PERSONALISATION_FAILURE";

export const SET_REMINDER_REQUEST = "SET_REMINDER_REQUEST";
export const SET_REMINDER_SUCCESS = "SET_REMINDER_SUCCESS";
export const SET_REMINDER_FAILURE = "SET_REMINDER_FAILURE";

export const UPLOAD_USER_IMAGE_REQUEST = "UPLOAD_USER_IMAGE_REQUEST";
export const UPLOAD_USER_IMAGE_SUCCESS = "UPLOAD_USER_IMAGE_SUCCESS";
export const UPLOAD_USER_IMAGE_FAILURE = "UPLOAD_USER_IMAGE_FAILURE";

export const CLEAR_PERSONALISATION_DATA = "CLEAR_PERSONALISATION_DATA";

export const SET_COMPANY = "SET_COMPANY";

/****** asset finance contract********/

export const CLEAR_AF_CONTRACT_SUCCESS = "CLEAR_AF_CONTRACT_SUCCESS";

export const GET_ASSET_CATEGORY_REQUEST = "GET_ASSET_CATEGORY_REQUEST";
export const GET_ASSET_CATEGORY_SUCCESS = "GET_ASSET_CATEGORY_SUCCESS";
export const GET_ASSET_CATEGORY_FAILURE = "GET_ASSET_CATEGORY_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const GET_AF_FILES_REQUEST = "GET_AF_FILES_REQUEST";
export const GET_AF_FILES_SUCCESS = "GET_AF_FILES_SUCCESS";
export const GET_AF_FILES_FAILURE = "GET_AF_FILES_FAILURE";

export const ADD_QUOTE = "ADD_QUOTE";

export const REMOVE_ASSET_REQUEST = "REMOVE_ASSET_REQUEST";
export const REMOVE_ASSET_SUCCESS = "REMOVE_ASSET_SUCCESS";
export const REMOVE_ASSET_FAILURE = "REMOVE_ASSET_FAILURE";

export const REMOVE_QUOTE_REQUEST = "REMOVE_QUOTE_REQUEST";
export const REMOVE_QUOTE_SUCCESS = "REMOVE_QUOTE_SUCCESS";
export const REMOVE_QUOTE_FAILURE = "REMOVE_QUOTE_FAILURE";

export const UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST";
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const UPDATE_ASSET_COST_QUOTE = "UPDATE_ASSET_COST_QUOTE";

export const ADD_ASSET_REQUEST = "ADD_ASSET_REQUEST";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_FAILURE = "ADD_ASSET_FAILURE";

export const CREATE_CONTRACT_REQUEST = "CREATE_CONTRACT_REQUEST";
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_FAILURE = "CREATE_CONTRACT_FAILURE";

export const UPDATE_FAVORITE_QUOTE_REQUEST = "UPDATE_FAVORITE_QUOTE_REQUEST";
export const UPDATE_FAVORITE_QUOTE_SUCCESS = "UPDATE_FAVORITE_QUOTE_SUCCESS";
export const UPDATE_FAVORITE_QUOTE_FAILURE = "UPDATE_FAVORITE_QUOTE_FAILURE";

export const REMOVE_CONTRACT_REQUEST = "REMOVE_CONTRACT_REQUEST";
export const REMOVE_CONTRACT_SUCCESS = "REMOVE_CONTRACT_SUCCESS";
export const REMOVE_CONTRACT_FAILURE = "REMOVE_CONTRACT_FAILURE";

export const GET_CONTRACT_REQUEST = "GET_CONTRACT_REQUEST";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILURE = "GET_CONTRACT_FAILURE";

export const VALIDATE_CONTRACT_REQUEST = "VALIDATE_CONTRACT_REQUEST";
export const VALIDATE_CONTRACT_SUCCESS = "VALIDATE_CONTRACT_SUCCESS";
export const VALIDATE_CONTRACT_FAILURE = "VALIDATE_CONTRACT_FAILURE";

export const ADD_QUOTE_REQUEST = "ADD_QUOTE_REQUEST";
export const ADD_QUOTE_SUCCESS = "ADD_QUOTE_SUCCESS";
export const ADD_QUOTE_FAILURE = "ADD_QUOTE_FAILURE";

export const RETURN_ASSET_FINANCE_CONTRACT_INITIAL_STATE =
  "RETURN_ASSET_FINANCE_CONTRACT_INITIAL_STATE";

/****** form ********/

export const UPDATE_FAST_DOC = "UPDATE_FAST_DOC";

export const MOVE_TO_FULL_DOC_FROM_FAST_DOC_CONTRACTS =
  "MOVE_TO_FULL_DOC_FROM_FAST_DOC_CONTRACTS";
export const RESET_ASSET_FINANCE_STATE = "RESET_ASSET_FINANCE";
export const AF_SELECT_BROKER = "AF_SELECT_BROKER";

/****** customers ********/
export const UPDATE_SELECTED_BUSINESS_STRUCTURE =
  "UPDATE_SELECTED_BUSINESS_STRUCTURE";

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const BF_GET_CUSTOMERS_REQUEST = "BF_GET_CUSTOMERS_REQUEST";
export const BF_GET_CUSTOMERS_SUCCESS = "BF_GET_CUSTOMERS_SUCCESS";
export const BF_GET_CUSTOMERS_FAILURE = "BF_GET_CUSTOMERS_FAILURE";

export const GET_CUSTOMER_DETAILS_REQUEST = "GET_CUSTOMER_DETAILS_REQUEST";
export const GET_CUSTOMER_DETAILS_SUCCESS = "GET_CUSTOMER_DETAILS_SUCCESS";
export const GET_CUSTOMER_DETAILS_FAILURE = "GET_CUSTOMER_DETAILS_FAILURE";

export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS_SUCCESS";
export const UPDATE_CUSTOMER_ADDRESS_DETAILS =
  "UPDATE_CUSTOMER_ADDRESS_DETAILS";

export const SET_ITEM_AT_PATH = "SET_ITEM_AT_PATH";

export const REMOVE_ITEM_AT_PATH = "DELETE_ITEM_AT_PATH";

export const ADD_NEW_CUSTOMER_REQUEST = "ADD_NEW_CUSTOMER_REQUEST";
export const ADD_NEW_CUSTOMER_SUCCESS = "ADD_NEW_CUSTOMER_SUCCESS";
export const ADD_NEW_CUSTOMER_FAILURE = "ADD_NEW_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const UPDATE_SELECTED_CONTRACT_ID = "UPDATE_SELECTED_CONTRACT_ID";

export const GET_GUARANTOR_INFO_REQUEST = "GET_GUARANTOR_INFO_REQUEST";
export const GET_GUARANTOR_INFO_SUCCESS = "GET_GUARANTOR_INFO_SUCCESS";

export const UPDATE_GUARANTOR_INFO_REQUEST = "UPDATE_GUARANTOR_INFO_REQUEST";
export const UPDATE_GUARANTOR_INFO_SUCCESS = "UPDATE_GUARANTOR_INFO_SUCCESS";
export const UPDATE_GUARANTOR_INFO_FAILURE = "UPDATE_GUARANTOR_INFO_FAILURE";

export const UPDATE_OFFICE_HOLDERS_REQUEST = "UPDATE_OFFICE_HOLDERS_REQUEST";
export const UPDATE_OFFICE_HOLDERS_SUCCESS = "UPDATE_OFFICE_HOLDERS_SUCCESS";
export const UPDATE_OFFICE_HOLDERS_FAILURE = "UPDATE_OFFICE_HOLDERS_FAILURE";

// Mind Matrix

export const MM_GET_LEADS_DATA_REQUEST = "MM_GET_LEADS_DATA_REQUEST";
export const MM_GET_LEADS_DATA_SUCCESS = "MM_GET_LEADS_DATA_SUCCESS";
export const MM_GET_LEADS_DATA_FAILURE = "MM_GET_LEADS_DATA_FAILURE";

export const MM_GET_CAMPAIGNS_REQUEST = "MM_GET_CAMPAIGNS_REQUEST";
export const MM_GET_CAMPAIGNS_SUCCESS = "MM_GET_CAMPAIGNS_SUCCESS";
export const MM_GET_CAMPAIGNS_FAILURE = "MM_GET_CAMPAIGNS_FAILURE";

export const MM_GET_OVERVIEW_REQUEST = "MM_GET_OVERVIEW_REQUEST";
export const MM_GET_OVERVIEW_SUCCESS = "MM_GET_OVERVIEW_SUCCESS";
export const MM_GET_OVERVIEW_FAILURE = "MM_GET_OVERVIEW_FAILURE";

export const MM_GET_EMAILS_REQUEST = "MM_GET_EMAILS_REQUEST";
export const MM_GET_EMAILS_SUCCESS = "MM_GET_EMAILS_SUCCESS";
export const MM_GET_EMAILS_FAILURE = "MM_GET_EMAILS_FAILURE";

export const MM_GET_NEWSLETTERS_REQUEST = "MM_GET_NEWSLETTERS_REQUEST";
export const MM_GET_NEWSLETTERS_SUCCESS = "MM_GET_NEWSLETTERS_SUCCESS";
export const MM_GET_NEWSLETTERS_FAILURE = "MM_GET_NEWSLETTERS_FAILURE";

export const MM_GET_SOCIAL_SYNDICATION_REQUEST =
  "MM_GET_SOCIAL_SYNDICATION_REQUEST";
export const MM_GET_SOCIAL_SYNDICATION_SUCCESS =
  "MM_GET_SOCIAL_SYNDICATION_SUCCESS";
export const MM_GET_SOCIAL_SYNDICATION_FAILURE =
  "MM_GET_SOCIAL_SYNDICATION_FAILURE";

// promotions

export const GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST";
export const GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS";
export const GET_PROMOTIONS_FAILURE = "GET_PROMOTIONS_FAILURE";

export const ADD_PROMOTION_REQUEST = "ADD_PROMOTION_REQUEST";
export const ADD_PROMOTION_SUCCESS = "ADD_PROMOTION_SUCCESS";
export const ADD_PROMOTION_FAILURE = "ADD_PROMOTION_FAILURE";

export const ADD_PROMOTION_IMAGE_REQUEST = "ADD_PROMOTION_IMAGE_REQUEST";
export const ADD_PROMOTION_IMAGE_SUCCESS = "ADD_PROMOTION_IMAGE_SUCCESS";
export const ADD_PROMOTION_IMAGE_FAILURE = "ADD_PROMOTION_IMAGE_FAILURE";

export const UPDATE_PROMOTION_REQUEST = "UPDATE_PROMOTION_REQUEST";
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS";
export const UPDATE_PROMOTION_FAILURE = "UPDATE_PROMOTION_FAILURE";

export const UPDATE_PROMOTION_ENABLE_REQUEST =
  "UPDATE_PROMOTION_ENABLE_REQUEST";
export const UPDATE_PROMOTION_ENABLE_SUCCESS =
  "UPDATE_PROMOTION_ENABLE_SUCCESS";
export const UPDATE_PROMOTION_ENABLE_FAILURE =
  "UPDATE_PROMOTION_ENABLE_FAILURE";

export const DELETE_PROMOTION_REQUEST = "DELETE_PROMOTION_REQUEST";
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS";
export const DELETE_PROMOTION_FAILURE = "DELETE_PROMOTION_FAILURE";

export const EDIT_PROMOTION = "EDIT_PROMOTION";
export const CANCEL_PROMOTION = "CANCEL_PROMOTION";

// Product Selector Tool

export const PST_SUBMIT_APPLICATION_REQUEST = "PST_SUBMIT_APPLICATION_REQUEST";
export const PST_SUBMIT_APPLICATION_SUCCESS = "PST_SUBMIT_APPLICATION_SUCCESS";
export const PST_SUBMIT_APPLICATION_FAILURE = "PST_SUBMIT_APPLICATION_FAILURE";

export const PST_GET_APPLICATION_REQUEST = "PST_GET_APPLICATION_REQUEST";
export const PST_GET_APPLICATION_SUCCESS = "PST_GET_APPLICATION_SUCCESS";
export const PST_GET_APPLICATION_FAILURE = "PST_GET_APPLICATION_FAILURE";

export const PST_SUBMIT_ENQUIRY_REQUEST = "PST_SUBMIT_ENQUIRY_REQUEST";
export const PST_SUBMIT_ENQUIRY_SUCCESS = "PST_SUBMIT_ENQUIRY_SUCCESS";
export const PST_SUBMIT_ENQUIRY_FAILURE = "PST_SUBMIT_ENQUIRY_FAILURE";

export const SET_PRODUCT_SELECTOR_RECOMMENDATION =
  "SET_PRODUCT_SELECTOR_RECOMMENDATION";
